import { useState } from "react";
import moment from "moment";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/features/fields/components/Tooltip";
import { switchString } from "../../utils/utils";
import { Autocomplete, Button, Input, Modal } from "src/components";
import downArrow from "src/assets/down-arrow.svg";
import { downloadInvoice, uploadInvoice } from "src/services/invoice-api";
import { generateUniqueID } from "src/constants/functions";
import InvoiceReject from "./invoice-reject";
import InvoiceReassign from "../invoice-master/InvoiceReassign";
import Contact from "src/assets/Contact.svg";
import { toast } from "src/hooks/useToast";
import Loader from "src/components/ui/loader.component";

interface InvoiceDetailCardProps {
  status: string;
  data: any;
  checkDataType: boolean;
  viewDetails?: boolean;
  isExpandable?: boolean;
  isExpandedInitial?: boolean;
  isCompleted?: boolean;
  isEntry?: boolean;
  index?: number;
  invoiceData?: any;
  handleAddItem?: any;
  showHeader?: boolean;
  fetchInvoiceDetails?: any;
}

const InvoiceDetailCard = ({
  data,
  checkDataType = true,
  status,
  isCompleted = false,
  isEntry = false,
  invoiceData,
  handleAddItem,
  showHeader = true,
  fetchInvoiceDetails,
}: InvoiceDetailCardProps) => {
  const [invoiceNumber, setInvoiceNumber] = useState<string>(invoiceData?.invoice?.invoice_number);
  const [invoiceDate, setInvoiceDate] = useState<string>(invoiceData?.invoice_date);
  const [qrIRN, setQrIRN] = useState<any>(data?.["QR/IRN"]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  console.log(invoiceDate);

  const [modalStates, setModalStates] = useState({
    reject: false,
    reassign: false,
    hold: false,
  });
  const [error, setError] = useState<string | null>(null);

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleInputChange = (e: any) => {
    const inputValue = e.target.value;

    if (/[^a-zA-Z0-9]/.test(inputValue)) {
      // If input contains special characters, show error
      setError("Special characters are not allowed in the invoice number.");
    } else {
      // Clear error
      setError(null);
    }

    setInvoiceNumber(inputValue); // Keep updating the value regardless
  };

  const verifyStringType = function (input: string) {
    const date = new Date(input);
    const numberRegex = /^\d+$/;
    if (!isNaN(date.getTime()) && input.trim() !== "" && !numberRegex.test(input)) {
      return moment(input).format("DD MMM, YYYY");
    } else {
      return input;
    }
  };

  const handleSubmitInvoice = async () => {
    setIsLoading(true);
    // if (data?.invoice !== "null") {
    //   toast({ description: "Invoice already exists", variant: "destructive" });

    //   return;
    // }
    if (error) {
      toast({ description: "Please enter valid invoice number", variant: "destructive" });
      return;
    }
    const payload = {
      data: {
        // invoice_id: 12,
        invoice_status: invoiceData?.fileStatusHdr?.fuid_status_shortcode,
        stockist_id: invoiceData?.stockist_id,
        stockist_invoice_attachment_id: invoiceData?.stockist_invoice_attachment_id,
        u_code: generateUniqueID(),
        invoice_number: invoiceNumber,
        // invoice_date: invoiceDate,
        qr_irn: qrIRN,
        // cash_discount_perc: 5.0,
        // cash_discount: 50.0,
        // net_amount: 950.0,
        // gst_perc: 18.0,
        // gross_amount: 1121.0,
        // gst_amount: 171.0,
        // created_by: "Avinash",
        // gst_status: invoiceData?.gst_validated === true ? "Validated" : "",
        counter_gst_number: invoiceData?.counter_gst_number || "",
        counter_dl_number: invoiceData?.counter_dl_number || "",
        counter_mci_number: invoiceData?.counter_mci_number || "",
        // assigned_to: 12,
        // updated_by: "avinash",
      },
      event_type: "INVOICE_ADD",
    };

    try {
      const res = await uploadInvoice(payload);
      console.log(res, "res");
      console.log(payload, "payload");
      setTimeout(async () => {
        setIsLoading(false);
        fetchInvoiceDetails();
      }, 2000);
    } catch (error) {
      console.log(error, "error");
      setIsLoading(false);
    }
    console.log("Submit Invoice");
  };

  const handleCloseModal = (modalName: string) => {
    setModalStates((prev: any) => ({ ...prev, [modalName]: false }));
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="-mt-10">
      <Modal isOpen={modalStates.reject} isHeader={false} onCloseModal={() => handleCloseModal("reject")} title="Reject Invoice">
        <InvoiceReject invoiceData={invoiceData} onCloseModal={() => handleCloseModal("reject")} />
      </Modal>
      <Modal isOpen={modalStates.reassign} isHeader={false} onCloseModal={() => handleCloseModal("reassign")} title="Reassign Invoice">
        <InvoiceReassign invoiceData={invoiceData} onCloseModal={() => handleCloseModal("reassign")} />
      </Modal>

      {showHeader && (
        <header className={isCompleted ? "flex justify-between w-full" : "flex justify-between w-full px-5 py-5"}>
          <div className="flex flex-col gap-x-2">
            <div className="flex items-center gap-x-2">
              <h1 className="font-semibold text-[1.5rem] mr-4">FUID {invoiceData?.fuid_no}</h1>
              <h1
                className={`font-semibold  flex items-center text-xs h-6 px-2 rounded-full`}
                style={{
                  backgroundColor: invoiceData?.fileStatusHdr?.color_code?.trim(),
                  color: invoiceData?.fileStatusHdr?.text_color_code?.trim(),
                }}
              >
                {status}
                <img className="w-3 h-3 ml-1" src={downArrow} alt="down-arrow" />
              </h1>
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            {
              <div className="flex items-center p-2 rounded-md bg-[#f1f1f1]">
                <Button
                  className="flex items-center px-4 space-x-2 font-normal bg-[#f1f1f1] border-gray-500 rounded-r-none border-r rounded-l-md h-5 hover:text-gray-600 focus:outline-none focus:ring-0"
                  onClick={() => {
                    downloadInvoice(invoiceData?.einv_attachment_path).then((res: any) => {
                      if (res?.data?.success && res?.data?.data) {
                        window.open(res?.data?.data, "_blank"); // Opens the link in a new tab
                      } else {
                        console.error("Failed to download the invoice.");
                      }
                    });
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 16v1a2 2 0 002 2h12a2 2 0 002-2v-1M7 8l5 5m0 0l5-5m-5 5V3" />
                  </svg>
                  <span>Download Invoice</span>
                </Button>
                <Button className="flex items-center px-4 space-x-2 font-normal bg-[#f1f1f1]  border-none rounded-none h-5 hover:text-gray-600 focus:outline-none focus:ring-0">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14M12 5l7 7-7 7" />
                  </svg>
                  <span>Preview</span>
                </Button>
                <Button className="flex items-center px-4 space-x-2 font-normal bg-[#f1f1f1] border-gray-500 border-l rounded-l-none rounded-r-md h-5 hover:text-gray-600 focus:outline-none focus:ring-0">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 5.636a9 9 0 11-12.728 0m12.728 0L12 12m0 0l-6.364-6.364" />
                  </svg>
                  <span>Report an issue</span>
                </Button>
              </div>
            }
          </div>
        </header>
      )}
      {showHeader && (
        <div className="flex items-center gap-x-2 ml-4">
          <img className="w-6 h-6 ml-1" src={Contact} alt="person-img" />
          <span className="text-[#49484C] text-sm">Assigned to</span>
          <div className="w-10 h-10 bg-[#8051FF] flex items-center justify-center text-[#fff] rounded-full">
            {invoiceData?.InvoiceAssignment?.user_name?.slice(0, 2)}
          </div>
          <span className="text-[#49484C] text-sm">{invoiceData?.InvoiceAssignment?.user_name}</span>
        </div>
      )}
      <div className={`overflow-hidden transition-all duration-500 px-5 ease-in-out`} style={{ transitionProperty: "max-height, opacity" }}>
        {
          <div className="flex justify-between pb-5 mt-10 border-b-2 border-gray-200">
            <p className="text-xl font-semibold">Header Information</p>
            <div className="p-2 bg-[#F1F1F1] border-2 border-gray-200 rounded-md cursor-pointer" onClick={handleToggle}>
              <img className={`transition-transform  duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
            </div>
          </div>
        }
        <div
          className={`overflow-hidden transition-all duration-500 ease-in-out ${isExpanded ? "opacity-100 max-h-[1000px]" : "max-h-0 opacity-0"}`}
          style={{ transitionProperty: "max-height, opacity" }}
        >
          <div className={"grid grid-cols-1 w-[100%] py-5 mt-5 xl:grid-cols-3 gap-y-5 mb-5"}>
            {!!data &&
              Object.keys(data)?.length > 0 &&
              Object.entries(data)?.map(
                ([key, value]) =>
                  typeof value === "string" &&
                  value !== "" && (
                    <div
                      key={key?.toString()}
                      className={
                        isEntry ? "grid grid-cols-2 text-sm xl:grid-cols-2" : "grid grid-cols-2 -space-x-10 -space-y-1 text-sm xl:grid-cols-2"
                      }
                    >
                      <p className="text-[#49484C] place-self-center mr-auto whitespace-break-spaces text-sm leading-[0.02rem] font-normal not-italic">
                        {switchString(key)} :
                      </p>

                      {key == "QR/IRN" && (
                        <div className="w-[60%]">
                          <Autocomplete
                            inputClassName="relative"
                            placeholder={key}
                            defaultValue={qrIRN}
                            suggestionList={[
                              { key: "QR", value: "QR" },
                              { key: "IRN", value: "IRN" },
                            ]}
                            onSelectionChange={(e: any) => {
                              setQrIRN(e.value);
                            }}
                            displayKey="key"
                          />
                        </div>
                      )}
                      {key === "Invoice no." && (
                        <div className="w-[100%]">
                          <Input
                            enableArrows={false}
                            showIcon={false}
                            placeholder={key}
                            type="text"
                            value={value.replace(/[^a-zA-Z0-9]/g, "")}
                            onChange={handleInputChange}
                            className="py-1 w-full text-left border rounded-lg border-gray-300"
                          />
                          <div
                            className={`mt-1 text-sm text-red-500 transition-all duration-500 ease-in-out ${
                              error ? "opacity-100 max-h-10" : "opacity-0 max-h-0"
                            }`}
                          >
                            {error}
                          </div>{" "}
                        </div>
                      )}

                      {key === "Invoice date" && (
                        <Input
                          enableArrows={false}
                          showIcon={false}
                          placeholder={key}
                          type="date"
                          value={value ? value.split("T")[0] : value}
                          className="py-1 w-full text-left border rounded-lg border-gray-300"
                          onChange={(e: any) => {
                            const dateValue = e.target.value;
                            const formattedDate = new Date(dateValue).toISOString();
                            setInvoiceDate(formattedDate);
                          }}
                        />
                      )}
                      {key !== "QR/IRN" && key !== "Invoice no." && key !== "Invoice date" && (
                        <div className="mr-auto">
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <p className="text-[#1B1A1F] text-sm text-left mr-auto">
                                  {checkDataType && typeof value === "string"
                                    ? verifyStringType(value)?.length > 20
                                      ? verifyStringType(value).slice(0, 20) + "..."
                                      : verifyStringType(value)
                                    : ""}
                                  {!checkDataType && value}
                                </p>
                              </TooltipTrigger>
                              <TooltipContent>
                                {checkDataType && (
                                  <p className="text-[#1B1A1F] mr-auto">{typeof value === "string" ? verifyStringType(value) : ""}</p>
                                )}
                                {!checkDataType && <p className="text-[#1B1A1F] mr-auto">{value}</p>}
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      )}
                    </div>
                  )
              )}
          </div>
        </div>
        <div className="flex justify-end mb-10 gap-x-2">
          <div className="flex gap-2">
            {invoiceData?.gst_validated === true && invoiceData?.dl_validated === true && qrIRN?.length > 0 && (
              <Button
                className="font-normal bg-[#F1F1F1] border-2 border-gray-200 shadow-sm h-9 focus:outline-none focus:ring-0 hover:text-gray-600"
                onClick={handleAddItem}
              >
                Add Item
              </Button>
            )}
            <div className="flex gap-4">
              <Button className="h-9 text-white font-normal bg-[#8051FF] shadow-sm" type="submit" onClick={handleSubmitInvoice}>
                Submit Invoice
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetailCard;

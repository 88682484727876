import { Input } from "src/components";
import Breadcrumb from "src/components/ui/breadcrumb.component";
import InvoiceEntry from "./invoice-entry.component";
// import InvoiceApproval from "../invoice-master/invoice-approval.component";
import InvoiceLayout from "../invoice-master/invoice-layout.component";
// import { InvoiceEntryScreen } from "../invoice-details/InvoiceEntryScreen";

// const tabs = ["Entry"];

const InvoiceHomepage = () => {
  return (
    <InvoiceLayout>
      <header className="flex flex-col justify-between py-5 mx-auto">
        <div className="flex justify-between px-[3.75rem]">
          <div className="">
            <Breadcrumb
              currentActive="invoice"
              dataOrigin={[
                { name: "Transactions", path: "transactions" },
                { name: "Invoices", path: "invoice" },
              ]}
            />
            <h1 className="text-[#49484C] font-bold text-[2rem]">Invoices</h1>
            {/* <p className="font-medium text-base text-[#929194]">Invoices</p> */}
          </div>

          <div className="flex gap-2 items-center">
            <Input placeholder="Search" className="px-3 h-[32px] w-[180px] rounded-lg " showIcon={true} />
          </div>
        </div>
        <div className="px-[3.75rem]">
          <InvoiceEntry />
        </div>
      </header>
    </InvoiceLayout>
  );
};

export default InvoiceHomepage;

import { useState } from "react";
import moment from "moment";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/features/fields/components/Tooltip";
import { switchString } from "../../utils/utils";
import { Button, Modal } from "src/components";
import InvoiceDropdown from "./invoice-menu.component";
import downArrow from "src/assets/down-arrow.svg";
import { useNavigate } from "react-router-dom";
import InvoiceReject from "../invoice-details/invoice-reject";
import InvoiceReassign from "../invoice-master/InvoiceReassign";
import Contact from "src/assets/Contact.svg";
import { downloadInvoice } from "src/services/invoice-api";
import InvoiceEscalate from "../invoice-master/InvoiceEscalate";
import InvoiceSetPriority from "../invoice-master/InvoiceSetPriority";
import star from "src/features/fields/assets/star.svg";
import starEmpty from "src/assets/starEmpty.svg";
import InvoiceOnHold from "../invoice-master/InvoiceOnHold";
import Download from "src/features/fields/assets/Download copy.svg";
import Reject from "src/features/fields/assets/Reject.svg";
import Escalate from "src/features/fields/assets/Escalate.svg";
import Priority from "src/features/fields/assets/Priority.svg";
import Hold from "src/features/fields/assets/Hold.svg";

const menuItems = [
  { label: "Re-assign Invoice", id: "reassign", icon: Contact },
  { label: "Put On hold", id: "hold", icon: Hold },
  { label: "Reject Invoice", id: "reject", icon: Reject },
  { label: "Download Invoice", id: "download", icon: Download },
  { label: "Download E-Inv Ack", id: "download-ack", icon: Download },
  { label: "Escalate Invoice", id: "escalate", icon: Escalate },
  { label: "Set Priority", id: "setpriority", icon: Priority },
];

interface InvoiceDetailCardProps {
  status: string;
  data: any;
  checkDataType: boolean;
  viewDetails?: boolean;
  isExpandable?: boolean;
  isExpandedInitial?: boolean;
  isEntry?: boolean;
  index?: number;
  invoiceData?: any;
  handleAddItem?: any;
}

const InvoiceHomeDetailCard = ({
  data,
  checkDataType = true,
  status,
  viewDetails = true,
  isExpandable = true,
  isExpandedInitial = false,
  index,
  invoiceData,
}: InvoiceDetailCardProps) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedInitial || index === 0);
  const naviagate = useNavigate();
  const [modalStates, setModalStates] = useState({
    reject: false,
    reassign: false,
    hold: false,
    escalate: false,
    setpriority: false,
  });

  const verifyStringType = function (input: string) {
    const date = new Date(input);
    const numberRegex = /^\d+$/;
    if (!isNaN(date.getTime()) && input.trim() !== "" && !numberRegex.test(input)) {
      return moment(input).format("DD MMM, YYYY");
    } else {
      return input;
    }
  };

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleCloseModal = (modalName: string) => {
    setModalStates((prev: any) => ({ ...prev, [modalName]: false }));
  };

  const handleOptionClick = (item: any) => {
    console.log("Selected option:", item);
    switch (item.id) {
      case "reject":
        setModalStates((prev: any) => ({ ...prev, reject: true }));
        break;
      case "hold":
        setModalStates((prev: any) => ({ ...prev, hold: true }));
        break;
      case "reassign":
        setModalStates((prev: any) => ({ ...prev, reassign: true }));
        break;
      case "download":
        downloadInvoice(invoiceData?.einv_attachment_path).then((res: any) => {
          if (res?.data?.success && res?.data?.data) {
            window.open(res?.data?.data, "_blank"); // Opens the link in a new tab
          } else {
            console.error("Failed to download the invoice.");
          }
        });
        break;
      case "download-ack":
        downloadInvoice(invoiceData?.einv_attachment_path).then((res: any) => {
          if (res?.data?.success && res?.data?.data) {
            const link = document.createElement("a");
            link.href = res?.data?.data; // File URL
            link.download = "invoice.pdf"; // Default filename (you can set it dynamically if needed)
            document.body.appendChild(link); // Append to the DOM
            link.click(); // Trigger the download
            document.body.removeChild(link); // Clean up
          } else {
            console.error("Failed to download the invoice.");
          }
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="mb-10 mt-4">
      <div className="hidden">
        <Modal isOpen={modalStates.reject} isHeader={false} onCloseModal={() => handleCloseModal("reject")} title="Reject Invoice">
          <InvoiceReject invoiceData={invoiceData} onCloseModal={() => handleCloseModal("reject")} />
        </Modal>
        <Modal isOpen={modalStates.reassign} isHeader={false} onCloseModal={() => handleCloseModal("reassign")} title="Reassign Invoice">
          <InvoiceReassign invoiceData={invoiceData} onCloseModal={() => handleCloseModal("reassign")} />
        </Modal>
        <Modal isOpen={modalStates.escalate} isHeader={false} onCloseModal={() => handleCloseModal("escalate")} title="Escalate Invoice">
          <InvoiceEscalate invoiceData={invoiceData} onCloseModal={() => handleCloseModal("escalate")} />
        </Modal>
        <Modal isOpen={modalStates.setpriority} isHeader={false} onCloseModal={() => handleCloseModal("setpriority")} title="Set Priority">
          <InvoiceSetPriority invoiceData={invoiceData} onCloseModal={() => handleCloseModal("setpriority")} />
        </Modal>
        <Modal isOpen={modalStates.hold} isHeader={false} onCloseModal={() => handleCloseModal("hold")} title="Put on hold">
          <InvoiceOnHold invoiceData={invoiceData} onCloseModal={() => handleCloseModal("hold")} />
        </Modal>
      </div>

      <header className={"flex justify-between w-full px-5 py-5"}>
        <div className="flex flex-col gap-x-2">
          <div className="flex items-center gap-x-2">
            {isExpanded && <div className="w-1.5 h-1.5 bg-red-500 rounded-full mr-4"></div>}{" "}
            {invoiceData?.is_prioritised && <img src={star} className="p-1 mr-2 w-8 h-8 rounded-lg border border-[#d0d0d0] shadow-md" alt="star" />}
            {!invoiceData?.is_prioritised && (
              <img src={starEmpty} className="p-1 mr-2 w-8 h-8 rounded-lg border border-[#d0d0d0] shadow-md" alt="star" />
            )}
            <h1 className={"font-semibold text-[1.2rem] mr-4"}>FUID {invoiceData?.fuid_no}</h1>
            <h1
              className={`font-semibold  flex items-center text-xs h-6 px-2 rounded-full`}
              style={{ backgroundColor: invoiceData?.fileStatusHdr?.color_code?.trim(), color: invoiceData?.fileStatusHdr?.text_color_code?.trim() }}
            >
              {status}
              <img className="w-3 h-3 ml-1" src={downArrow} alt="down-arrow" />
            </h1>
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          {viewDetails && (
            <Button
              className="h-10 text-white bg-[#8051FF] shadow-sm"
              onClick={() => {
                naviagate(`/transactions/invoice/${invoiceData?.stockist_invoice_attachment_id}`);
              }}
            >
              View Details
            </Button>
          )}
          {
            <div className="w-10 h-10 text-white shadow-sm">
              <InvoiceDropdown
                placeholder="..."
                menuItems={menuItems}
                displayKey="label"
                onOptionClick={handleOptionClick}
                currentSelected={undefined}
                setModalStates={setModalStates}
              />
            </div>
          }
          {isExpandable && (
            <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black" onClick={handleToggle}>
              <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
            </Button>
          )}
        </div>
      </header>
      <div className="flex items-center gap-x-2 ml-4">
        <img className="w-6 h-6 ml-1" src={Contact} alt="person-img" />
        <span className="text-[#49484C] text-sm">Assigned to</span>
        <div className="w-10 h-10 bg-[#8051FF] flex items-center justify-center text-[#fff] rounded-full">
          {invoiceData?.InvoiceAssignment?.user_name?.slice(0, 2)}
        </div>
        <span className="text-[#49484C] text-sm">{invoiceData?.InvoiceAssignment?.user_name}</span>
      </div>
      <div
        className={`overflow-hidden transition-all duration-500 ease-in-out ${isExpanded ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"}`}
        style={{ transitionProperty: "max-height, opacity" }}
      >
        <div className={"grid grid-cols-1 w-[60%] gap-x-10 px-5 py-5 mt-5 xl:grid-cols-2 gap-y-5 mb-5"}>
          {!!data &&
            Object.keys(data)?.length > 0 &&
            Object.entries(data)?.map(
              ([key, value]) =>
                typeof value === "string" &&
                value !== "" && (
                  <div key={key?.toString()} className={"grid grid-cols-2 -space-x-10 -space-y-1 text-sm xl:grid-cols-2"}>
                    <p className="text-[#49484C] place-self-center mr-auto whitespace-break-spaces text-sm leading-[0.02rem] font-normal not-italic">
                      {switchString(key)} :
                    </p>
                    <div className="mr-auto">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            <p className="text-[#1B1A1F] text-sm text-left mr-auto">
                              {checkDataType && typeof value === "string"
                                ? verifyStringType(value)?.length > 20
                                  ? verifyStringType(value).slice(0, 20) + "..."
                                  : verifyStringType(value)
                                : ""}
                              {!checkDataType && value}
                            </p>
                          </TooltipTrigger>
                          <TooltipContent>
                            {checkDataType && <p className="text-[#1B1A1F] mr-auto">{typeof value === "string" ? verifyStringType(value) : ""}</p>}
                            {!checkDataType && <p className="text-[#1B1A1F] mr-auto">{value}</p>}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  </div>
                )
            )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceHomeDetailCard;

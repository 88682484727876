import { Button, Spinner } from "src/components";
import { useEffect, useState } from "react";
import downArrow from "src/assets/down-arrow.svg";
import saveFile from "src/features/fields/assets/saveFile.svg";
import send from "src/features/fields/assets/send.svg";
import cross from "src/features/fields/assets/cross.svg";
import star from "src/features/fields/assets/star.svg";
import { editInvoiceProduct, getInvoiceDetails, getSimilarOrders } from "src/services/invoice-api";
import { invoiceActions } from "src/context/invoice-slice";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InvoiceDetailCard from "./invoice-detail.component";
import { getInvoiceDetailsInfo } from "../invoice-master/invoice-helper";
import { RootState } from "src/context/store";
import { useSelector } from "react-redux";
import { useToast } from "src/hooks/useToast";

const DEFAULT_TABLE_DATA = [
  {
    type: "Quantity",
    invoiceData: "",
    orderedData: "",
    modifiedData: "",
  },
  {
    type: "Discount Type",
    invoiceData: "",
    orderedData: "",
    modifiedData: "",
  },
  {
    type: "Cash Discount",
    invoiceData: "",
    orderedData: "",
    modifiedData: "",
  },
  {
    type: "PTR",
    invoiceData: "",
    orderedData: "",
    modifiedData: "",
  },
  {
    type: "MRP",
    invoiceData: "",
    orderedData: "",
    modifiedData: "",
  },
];

export const InvoiceApprovalScreen = () => {
  const { stockist_invoice_attachment_id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { invoiceApprovalData } = useSelector((state: RootState) => state.invoice);
  const [mappedData, setMappedData] = useState<any[]>([]);
  const [selectedItemDetails, setSelectedItemDetails] = useState<any>(null);
  const [tableData, setTableData] = useState(DEFAULT_TABLE_DATA);
  const { toast } = useToast();

  const fetchInvoiceDetails = async () => {
    setIsLoading(true);
    try {
      const response = await getInvoiceDetails(stockist_invoice_attachment_id as string, false);
      dispatch(invoiceActions.setInvoiceApprovalData(response?.data?.data));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(mappedData);

  const subtotal = mappedData?.reduce((acc: number, item: any) => acc + Number(item?.quantity) * Number(item?.ptr), 0);

  console.log(subtotal);

  const handleViewDetails = async (item: any) => {
    // Populate the second table with selected item details

    const response = await getSimilarOrders({
      item_code: item?.item_code,
      inv_mrp: item?.inv_mrp,
      counter_code: invoiceApprovalData?.counter_code,
      company_stockist_code: invoiceApprovalData?.company_stockist_code,
    });

    console.log(response, "response");

    const data = response?.data?.data?.[0];

    const newTableData = [
      {
        type: "Quantity",
        invoiceData: item?.quantity,
        orderedData: data?.total_remaining_quantity,
        // modifiedData: item?.quantity,
        modifiedData: item?.quantity,
      },
      {
        type: "Discount Type",
        invoiceData: item?.price_identifier,
        orderedData:
          data?.price_identifier == "1"
            ? "Discount or PTR : " + data?.discount_on_ptr
            : data?.price_identifier == "2"
              ? "Scheme : " + data?.scheme_quantity + " + " + data?.scheme_free_quantity
              : "Fixed Price : " + data?.fixed_price,
        modifiedData: item?.price_identifier,
        // modifiedData: data?.price_identifier,
      },
      {
        type: "Cash Discount",
        invoiceData: item?.discount_amt,
        orderedData:
          data?.price_identifier == "1"
            ? data?.discount_on_ptr
            : data?.price_identifier == "2"
              ? ((data?.scheme_free_quantity * data?.price) / data?.scheme_free_quantity) * data?.price * 100
              : (data?.fixed_price / data?.ptr) * 100,
        modifiedData: item?.discount_amt,
        // modifiedData: data?.item_discount,
      },
      {
        type: "PTR",
        invoiceData: item?.inv_rate,
        orderedData: data?.ptr,
        modifiedData: item?.inv_rate,
        // modifiedData: data?.mrp,
      },
      {
        type: "MRP",
        invoiceData: item?.ptr,
        orderedData: data?.mrp,
        // modifiedData: data?.ptr,
        modifiedData: item?.ptr,
      },
    ];

    setSelectedItemDetails(item);
    setTableData(newTableData);
  };

  const handleModifyData = (index: number, value: string) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].modifiedData = value;
    setTableData(updatedTableData);
  };

  const handleSaveModifiedData = () => {
    if (!selectedItemDetails) return;

    const modifiedItem = {
      ...selectedItemDetails,
      quantity: Number(tableData.find((item) => item.type === "Quantity")?.modifiedData),
      price_identifier: tableData.find((item) => item.type === "Discount Type")?.modifiedData,
      discount_amt: tableData.find((item) => item.type === "Cash Discount")?.modifiedData,
      inv_rate: tableData.find((item) => item.type === "PTR")?.modifiedData,
      mrp: tableData.find((item) => item.type === "MRP")?.modifiedData,
      invoice_id: invoiceApprovalData?.invoice?.invoice_id,
      company_stockist_code: String(invoiceApprovalData?.company_stockist_code),
      counter_code: invoiceApprovalData?.counter_code,
    };

    // Check if item with same item_code already exists
    const itemExists = mappedData.some((item) => item.item_code === modifiedItem.item_code);

    if (itemExists) {
      toast({
        title: "Duplicate Item",
        description: "This item has already been mapped.",
        variant: "destructive",
      });
      return;
    }

    setMappedData((prev) => [...prev, modifiedItem]);

    setSelectedItemDetails(null);
    setTableData(DEFAULT_TABLE_DATA);

    toast({
      title: "Data Mapped Successfully",
      description: "Item has been added to mapped data.",
    });
  };

  const handleReset = () => {
    setMappedData([]);
    setTableData(DEFAULT_TABLE_DATA);
    setSelectedItemDetails(null);
    toast({
      title: "Data Reset Successfully",
      description: "All data has been reset.",
    });
  };

  const handleApproveInvoice = async () => {
    const payload = {
      data: mappedData,
      primary_key: invoiceApprovalData?.invoice?.invoice_id,
      event_type: "INVOICE_PRODUCT_EDIT",
    };
    const response = await editInvoiceProduct(payload);
    console.log(response);
    console.log(payload);
  };

  useEffect(() => {
    fetchInvoiceDetails();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="mx-auto mt-10">
      <div className="p-5 rounded-xl border border-[#d0d0d0] shadow-sm">
        <header className="w-full">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <img src={star} className="p-1 mr-2 w-8 h-8 rounded-lg border border-[#d0d0d0] shadow-sm" alt="star" />
              <h1 className={"font-semibold text-lg text-[1.5rem] mr-4"}>{invoiceApprovalData?.fuid_no}</h1>
              <h1 className={`font-semibold  flex items-center text-xs h-6 px-2 rounded-full ${"bg-[#FFF1E3] text-[#5E4200]"}`}>
                {invoiceApprovalData?.fileStatusHdr?.fuid_status_desc}
                <img className="ml-1 w-3 h-3 cursor-pointer" src={downArrow} alt="down-arrow" />
              </h1>
            </div>
            <div className="flex gap-x-1.5">
              <Button className="font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0] shadow-sm h-8 focus:outline-none focus:ring-0 hover:text-gray-600">
                <img src={saveFile} className="mr-1 w-4 h-4" alt="save-file" />
                Save{" "}
              </Button>
              <Button className="font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0] shadow-sm h-8 focus:outline-none focus:ring-0 hover:text-gray-600">
                <img src={send} className="mr-1 w-4 h-4" alt="send" />
                Send{" "}
              </Button>
              <Button className="font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0] shadow-sm h-8 focus:outline-none focus:ring-0 hover:text-gray-600">
                <img src={cross} className="mr-1 w-4 h-4 invert" alt="cross" />
                Clear{" "}
              </Button>
            </div>
          </div>
        </header>

        <ul className="mt-5">
          <li className="my-5 mb-10 rounded-lg">
            <InvoiceDetailCard
              isExpandedInitial={true}
              isExpandable={false}
              status={invoiceApprovalData?.fileStatusHdr?.fuid_status_desc}
              checkDataType={false}
              data={getInvoiceDetailsInfo(invoiceApprovalData)}
              viewDetails={false}
              invoiceData={invoiceApprovalData}
              showHeader={false}
              fetchInvoiceDetails={fetchInvoiceDetails}
            />
          </li>
        </ul>

        <div className="mt-10 mb-10 rounded-lg px-4">
          <div className="flex justify-between items-center pb-5 border-b-2 border-gray-200">
            <p className="mb-2 text-lg font-semibold">Invoice Details</p>
          </div>

          <table className="mt-5 min-w-full border border-gray-200">
            <thead className="w-full bg-gray-100">
              <tr>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Item code</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Item description</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Batch</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Qty.</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Rate</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Prd. Dis.</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Scheme</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Cash Dis.</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">GST % </th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Net Amt.</th>
                <th className="px-4 py-2 text-sm text-left text-gray-600">Order Amt. Entry</th>
                <th className="px-4 py-2 w-36 text-sm text-left text-gray-600">Order Map</th>
              </tr>
            </thead>
            <tbody>
              {invoiceApprovalData?.invoice?.invoiceProducts?.map((item: any, index: any) => (
                <tr key={index} className={index % 2 === 0 ? "bg-gray-50 text-sm" : "text-sm"}>
                  <td className="px-4 py-2 border-t">{item?.item_code}</td>
                  <td className="px-4 py-2 border-t">{item?.item_description}</td>
                  <td className="px-4 py-2 border-t">{item?.batch_no}</td>
                  <td className="px-4 py-2 border-t">{item?.quantity}</td>
                  <td className="px-4 py-2 border-t">{item?.inv_rate}</td>
                  <td className="px-4 py-2 border-t">{item?.discount_amt}</td>
                  <td className="px-4 py-2 border-t">{item?.price_identifier}</td>
                  <td className="px-4 py-2 border-t">{item?.discount_amt}</td>
                  <td className="px-4 py-2 border-t">{item?.gst}</td>
                  <td className="px-4 py-2 border-t">{item?.gross_amt}</td>
                  <td className="px-4 py-2 border-t">{item?.gross_amt}</td>
                  <td className="px-4 py-2 border-t">
                    <Button onClick={() => handleViewDetails(item)} className="w-full h-8 font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0]">
                      View Details
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-10 border border-[#d0d0d0] p-5 rounded-xl mx-4">
          <p className="text-lg font-semibold">{selectedItemDetails?.item_description || "Select an Item"}</p>

          <table className="mt-5 min-w-full border border-gray-200">
            <thead className="w-full bg-gray-100">
              <tr>
                <th className="px-4 py-2 text-sm font-normal text-left text-gray-600"></th>
                <th className="px-4 py-2 text-sm font-normal text-left text-gray-600">Invoice Data</th>
                <th className="px-4 py-2 text-sm font-normal text-left text-gray-600">Ordered Data</th>
                <th className="px-4 py-2 text-sm font-normal text-left text-gray-600">Modified Data</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr key={index} className="h-12 text-sm">
                  <td className="px-4 py-2 text-gray-500 border-t">{item.type}</td>
                  <td className="px-4 py-2 border-t">{item.invoiceData}</td>
                  <td className="px-4 py-2 border-t">{item.orderedData}</td>
                  <td className="px-4 py-2 border-t">
                    <input
                      disabled={item.type === "MRP"}
                      type="text"
                      value={item.modifiedData}
                      onChange={(e) => handleModifyData(index, e.target.value)}
                      className="px-2 py-1 w-full rounded-md border border-1-black"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-end">
            {selectedItemDetails && (
              <Button onClick={handleSaveModifiedData} className="mt-4 h-9 font-normal text-white bg-[#8051FF] shadow-sm">
                Save Modified Data
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="mt-10 border border-[#d0d0d0] p-5 rounded-xl px-10">
        <div className="flex justify-between items-center pb-5">
          <p className="text-lg font-semibold">Final Mapped Data</p>
          {/* <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black" onClick={handleToggle}>
            <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
          </Button> */}
          <Button
            className="font-normal bg-[#F1F1F1] border-2 border-[#d0d0d0] shadow-sm h-8 focus:outline-none focus:ring-0 hover:text-gray-600"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>

        <table className="min-w-full border border-gray-200">
          <thead className="w-full bg-gray-100">
            <tr>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Item code</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Item description</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Batch</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Qty.</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Rate</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Prd. Dis.</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Scheme</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Inv. Cash Dis.</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">GST % </th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Net Amt.</th>
              <th className="px-4 py-2 text-sm text-left text-gray-600">Order Amt. Entry</th>
            </tr>
          </thead>
          <tbody>
            {mappedData?.map((item: any, index: any) => (
              <tr key={index} className={index % 2 === 0 ? "bg-gray-50 text-sm h-12" : "text-sm h-12"}>
                <td className="px-4 py-2 border-t">{item?.item_code}</td>
                <td className="px-4 py-2 border-t">{item?.item_description}</td>
                <td className="px-4 py-2 border-t">{item?.batch_no}</td>
                <td className="px-4 py-2 border-t">{item?.quantity}</td>
                <td className="px-4 py-2 border-t">{item?.inv_rate}</td>
                <td className="px-4 py-2 border-t">{item?.discount_amt}</td>
                <td className="px-4 py-2 border-t">{item?.price_identifier}</td>
                <td className="px-4 py-2 border-t">{item?.discount_amt}</td>
                <td className="px-4 py-2 border-t">{item?.gst}</td>
                <td className="px-4 py-2 border-t">{item?.gross_amt}</td>
                <td className="px-4 py-2 border-t">{item?.gross_amt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="p-6 mt-10 rounded-xl border border-[#d0d0d0] w-[360px] shadow-sm">
        <p className="mb-2 text-lg font-semibold">Calculations</p>
        <div className="flex flex-col gap-y-1 pb-10">
          <p className="flex justify-between py-2 text-sm">
            Subtotal <span className="">₹ {subtotal.toFixed(2)}</span>
          </p>
          <p className="flex justify-between py-2 text-sm">
            Cash Discount % <input type="text" className="px-10 py-1 w-10 rounded-md border border-1-black" />
          </p>
          <p className="flex justify-between py-2 text-sm">
            Cash Discount Amount <input type="text" className="px-10 py-1 w-10 rounded-md border border-1-black" />
          </p>
          <p className="flex justify-between py-2 text-sm">
            Total (as calculated) <span className="font-semibold">₹{subtotal.toFixed(2)}</span>
          </p>
          <p className="flex justify-between py-2 text-sm">
            Net Inv. Amt. <input type="text" className="px-10 py-1 w-10 rounded-md border border-1-black" />
          </p>
          <Button className="w-full h-9 mt-5 font-normal text-white bg-[#8051FF] shadow-sm" onClick={handleApproveInvoice}>
            Approve Invoice
          </Button>
        </div>
      </div>
    </div>
  );
};

import { Tab, Transition } from "@headlessui/react";
import InvoiceCard from "../invoice-master/invoice-card";
import classNames from "classnames";
import AllInvoices from "./invoice-all.component";
import { Fragment, useEffect, useState } from "react";
import {
  fetchEscalatedInvoicesData,
  fetchInvoicesData,
  fetchInvoiceStatus,
  fetchPriorityInvoicesData,
  getAssigneeList,
} from "src/services/invoice-api";
import { invoiceActions } from "src/context/invoice-slice";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "src/hooks/useToast";
import Loader from "src/components/ui/loader.component";
import filterIcon from "src/assets/filterIcon.svg";
import { Button } from "src/components";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "src/components/ui/select.component";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import { getAllCompanyDetails } from "src/services/company-api";
import { RootState } from "src/context/store";
import InvoiceFiltersComponent from "../../features/fields/container/InvoiceFilters";
import { queryDivisionDetails } from "../../services/division-api";
import { getCityDetails } from "src/services/region-api";
import { getDistrictDetails } from "src/services/region-api";
import { getStateDetails } from "src/services/region-api";

const categories = ["All invoices", "Priority tasks", "Escalated"];

const InvoiceEntry = () => {
  const [selectedTab, setSelectedTab] = useState(0); // Track the active tab
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const dispatch = useDispatch();
  const { InvoiceFiltersData, InvoiceFilters } = useSelector((state: RootState) => state.invoice);
  const { toast } = useToast();

  const ref = useOutsideClick(() => {
    setIsFilterOpen(false);
  });

  const fetchTabData = async (tabIndex: number) => {
    setIsLoading(true);
    try {
      let response;

      switch (tabIndex) {
        case 0:
          response = await fetchInvoicesData({
            company_code: InvoiceFilters?.company?.[0]?.code,
            division_code: InvoiceFilters?.division?.[0]?.company_divisioncode,
            startDate: InvoiceFilters?.dateRange?.[0],
            endDate: InvoiceFilters?.dateRange?.[1],
            assignee_id: InvoiceFilters?.assignee?.[0]?.invoice_assignment_id,
            city: InvoiceFilters?.city?.[0]?.city_name?.split("|")?.[0]?.trim(),
            district: InvoiceFilters?.district?.[0]?.district_name,
            state: InvoiceFilters?.state?.[0]?.state_name,
            fuid_no: InvoiceFilters?.fuidNo?.[0],
            fuid_status_id: InvoiceFilters?.status?.[0]?.fuid_status_id,
          });
          break;
        case 1:
          response = await fetchPriorityInvoicesData();
          break;
        case 2:
          response = await fetchEscalatedInvoicesData();
          break;
        default:
          return;
      }

      const data = response?.data?.data?.invoiceData || [];
      dispatch(invoiceActions.setInvoiceData(data));
      toast({ description: "Invoice data fetched successfully", variant: "default" });
    } catch (error) {
      console.error("Error fetching invoices data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCompanyDetails()
      .then((res) => dispatch(invoiceActions.setInvoiceFiltersData({ key: "company", value: res?.data })))
      .catch((err) => console.log(err));
  }, [dispatch]);

  useEffect(() => {
    if (InvoiceFilters?.company?.length) {
      console.log(InvoiceFilters?.company?.[0]?.code);
      queryDivisionDetails(`/details/?fields=division_name&company_code=${InvoiceFilters?.company?.[0]?.code}`)
        .then((res) => dispatch(invoiceActions.setInvoiceFiltersData({ key: "division", value: res?.data })))
        .catch((err) => console.log(err));

      // fetchStockistFilterData(InvoiceFilters?.company?.[0]?.code)
      //   .then((res) => dispatch(invoiceActions.setInvoiceFiltersData({ key: "stockist", value: res?.data?.data })))
      //   .catch((err) => console.log(err));
    } else {
      dispatch(invoiceActions.setInvoiceFiltersData({ key: "division", value: [] }));
    }
  }, [InvoiceFilters?.company]);

  useEffect(() => {
    // Fetch data for the default tab when the component loads
    fetchTabData(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    fetchTabData(selectedTab);
  }, [
    InvoiceFilters?.company,
    InvoiceFilters?.division,
    InvoiceFilters?.status,
    InvoiceFilters?.assignee,
    InvoiceFilters?.city,
    InvoiceFilters?.district,
    InvoiceFilters?.state,
    InvoiceFilters?.fuidNo,
  ]);

  useEffect(() => {
    if (InvoiceFiltersData?.status?.length === 0) {
      fetchInvoiceStatus()
        .then((res) => dispatch(invoiceActions.setInvoiceFiltersData({ key: "status", value: res?.data })))
        .catch((err) => console.log(err));
    }
    if (InvoiceFiltersData?.assignee?.length === 0) {
      getAssigneeList()
        .then((res) => dispatch(invoiceActions.setInvoiceFiltersData({ key: "assignee", value: res?.data?.data })))
        .catch((err) => console.log(err));
    }
    if (InvoiceFiltersData?.city?.length === 0) {
      getCityDetails("")
        .then((res) => {
          dispatch(invoiceActions.setInvoiceFiltersData({ key: "city", value: res?.data?.data }));
        })
        .catch((err) => console.log(err));
    }
    if (InvoiceFiltersData?.district?.length === 0) {
      getDistrictDetails("")
        .then((res) => {
          dispatch(invoiceActions.setInvoiceFiltersData({ key: "district", value: res?.data?.data }));
        })
        .catch((err) => console.log(err));
    }
    if (InvoiceFiltersData?.state?.length === 0) {
      getStateDetails("")
        .then((res) => {
          dispatch(invoiceActions.setInvoiceFiltersData({ key: "state", value: res?.data?.data }));
        })
        .catch((err) => console.log(err));
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="w-full">
      <Transition
        as={Fragment}
        show={isFilterOpen}
        enter="transition transition ease-in-out duration-200 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition  ease-in-out duration-200 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="fixed right-0 inset-0 top-0 z-[500]">
          <InvoiceFiltersComponent
            companyDetails={InvoiceFiltersData?.company || []}
            divisionDetails={InvoiceFiltersData?.division || []}
            stockistDetails={InvoiceFiltersData?.stockist || []}
            statusDetails={InvoiceFiltersData?.status || []}
            assigneeDetails={InvoiceFiltersData?.assignee || []}
            cityDetails={InvoiceFiltersData?.city || []}
            districtDetails={InvoiceFiltersData?.district || []}
            stateDetails={InvoiceFiltersData?.state || []}
            ref={ref}
            onClose={() => setIsFilterOpen(false)}
          />
        </div>
      </Transition>
      <div className="mx-auto">
        <section className="flex flex-wrap justify-between mx-auto mt-10">
          <InvoiceCard title={"4,204"} subtitle="New invoices this month" heading="Total invoices" />
          <InvoiceCard title={"21"} subtitle="Invoices this month" heading="Invoices escalated" />
          <InvoiceCard title={"4,024"} subtitle="New invoices this month" heading="MTD" />
          <InvoiceCard title={"4,204"} subtitle="New invoices this month" heading="Total invoices" />
        </section>
        <div className="px-2 py-16 w-full sm:px-0">
          <Tab.Group
            selectedIndex={selectedTab}
            onChange={(index) => setSelectedTab(index)} // Update the selected tab
          >
            <div className="flex justify-between">
              <Tab.List className="flex p-1 space-x-1 rounded-lg bg-[#e3e3e3] w-[500px] h-[38px] items-center">
                {categories.map((category, index) => (
                  <Tab
                    key={index}
                    className={({ selected }) =>
                      classNames(
                        "w-full rounded-md py-1.5 text-sm font-normal leading-5",
                        "focus:outline-none",
                        selected ? "bg-white" : "hover:bg-white/[0.12] hover:text-black"
                      )
                    }
                  >
                    {category}
                  </Tab>
                ))}
              </Tab.List>
              <div className="flex gap-2 h-full">
                <div className="flex items-center w-[400px]">
                  {/* Company Dropdown */}
                  <Select>
                    <SelectTrigger className="w-[150px] shadow-none text-gray-700 bg-[#F1F1F1] border-none rounded-r-none rounded-l-md border-r-2 border-gray-500 focus:outline-none focus:ring-0">
                      <SelectValue placeholder="Company" />
                    </SelectTrigger>
                    <SelectContent className="bg-white">
                      <SelectGroup>
                        <SelectLabel>Companies</SelectLabel>
                        <SelectItem value="company1">Company 1</SelectItem>
                        <SelectItem value="company2">Company 2</SelectItem>
                        <SelectItem value="company3">Company 3</SelectItem>
                        <SelectItem value="company4">Company 4</SelectItem>
                        <SelectItem value="company5">Company 5</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  {/* <Input placeholder="Search" className="w-full py-2 bg-[#F1F1F1] border-none" /> */}
                  <input
                    placeholder="Search"
                    className="w-full px-2 py-1.5 text-gray-700 bg-[#F1F1F1] border-none rounded-l-none rounded-r-md focus:outline-none focus:ring-0"
                  />
                </div>
                <Button className="bg-[#F1F1F1]" onClick={() => setIsFilterOpen(true)}>
                  <img src={filterIcon} alt="filter-icon" />
                </Button>
                <Select>
                  <SelectTrigger className="w-[160px] bg-[#F1F1F1]">
                    <SelectValue placeholder="Assignee: Vishnu" />
                  </SelectTrigger>
                  <SelectContent className="bg-white">
                    <SelectGroup>
                      <SelectLabel>Assignee</SelectLabel>
                      <SelectItem value="apple">Vishnu</SelectItem>
                      <SelectItem value="banana">Sonika</SelectItem>
                      <SelectItem value="blueberry">Raunak</SelectItem>
                      <SelectItem value="grapes">Rishit</SelectItem>
                      <SelectItem value="pineapple">Tushar</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <Tab.Panels className="mt-2">
              {categories.map((_, index) => (
                <Tab.Panel
                  key={index}
                  className={classNames("w-full bg-white rounded-xl", "ring-offset-2 ring-white/60 focus:outline-none focus:ring-2")}
                >
                  <AllInvoices />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
};

export default InvoiceEntry;

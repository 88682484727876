import InvoiceHomeDetailCard from "./InvoiceHomeDetailCard";
import { getInvoiceDetailsCardInfo } from "../invoice-master/invoice-helper";
import { RootState } from "src/context/store";
import { useSelector } from "react-redux";

const AllInvoices = ({ isExpandable, isExpandedInitial }: { isExpandable?: boolean; isExpandedInitial?: boolean }) => {
  const { invoiceData } = useSelector((state: RootState) => state.invoice);

  return (
    <div className="mt-10 w-full">
      <ul>
        {invoiceData?.map((data: any, index: number) => (
          <li className="my-5 rounded-xl border-2 shadow-sm border-1-black">
            <InvoiceHomeDetailCard
              status={data?.fileStatusHdr?.fuid_status_desc}
              isExpandable={isExpandable}
              isExpandedInitial={isExpandedInitial}
              data={getInvoiceDetailsCardInfo(data)}
              checkDataType={false}
              invoiceData={data}
              viewDetails={true}
              index={index}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AllInvoices;

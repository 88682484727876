import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "src/components";
import { ordActions } from "src/context/order-slice";
import { RootState } from "src/context/store";
import { useToast } from "src/hooks/useToast";
import { getCounterByEmployee, getCounterOrderProductsById } from "src/services/order-api";
import cross from "../../assets/cross.svg";
import PurpleLeftArrowIcon from "../../assets/purple-left-arrow.svg";
import Button from "../../components/Button";
import Input from "src/components/ui/input.component";

const OrdersProductScreen = () => {
  const { locationCode } = useParams();
  // const locationCodeFixed = "141439";
  const { productsData, cartDataObj, selectedCounterData } = useSelector((state: RootState) => state.ord);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const counterCode = window.sessionStorage.getItem("counterOrderId");
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);

  const { toast } = useToast();

  const handleAddToCart = (item: any) => {
    const counter_product_lnk_id = item.counter_product_lnk_id;

    const existingItem = cartDataObj?.[counter_product_lnk_id];
    const currentQty = existingItem?.cartQty || item?.counter_product_slab?.[0]?.min_order_qty;
    const newQty = Number(currentQty);

    dispatch(
      ordActions.setCartDataObj({
        key: counter_product_lnk_id,
        value: {
          ...item,
          cartQty: newQty,
        },
      })
    );
  };

  const handleIncrement = (counter_product_lnk_id: string) => {
    const currentProd = cartDataObj?.[counter_product_lnk_id];
    const currentQty = currentProd?.cartQty || 0;
    const newQty = Number(currentQty) + 1;
    if (newQty > currentProd?.counter_product_slab?.[0]?.max_order_qty) {
      toast({ description: `Maximum order quantity is ${currentProd?.counter_product_slab?.[0]?.max_order_qty}`, variant: "destructive" });
      return;
    } else {
      dispatch(
        ordActions.setCartDataObj({
          key: counter_product_lnk_id,
          value: {
            ...currentProd,
            cartQty: newQty,
          },
        })
      );
    }
  };

  // const handleDiscount = (productId: any, value: any) => {
  //   setCart((prev: any) => prev.map((cartItem: any) => (cartItem.counter_product === productId ? { ...cartItem, scheme: value } : cartItem)));
  // };

  // const handleDivisionChange = (productId: any, value: any) => {
  //   setCart((prev: any) => prev.map((cartItem: any) => (cartItem.counter_product === productId ? { ...cartItem, division: value } : cartItem)));
  // };

  const handleReviewOrder = () => {
    if (!Object.keys(cartDataObj)?.length) {
      toast({
        description: "Please select at least one product",
      });
      return;
    }
    navigate(`/transactions/orders/review/${locationCode}`);
  };

  const handleDecrement = (counter_product_lnk_id: string) => {
    const currentProd = cartDataObj?.[counter_product_lnk_id];
    const currentQty = currentProd?.cartQty || 0;
    const newQty = Number(currentQty) - 1;
    if (newQty < currentProd?.counter_product_slab?.[0]?.min_order_qty) {
      dispatch(ordActions.removeCartDataObj({ key: counter_product_lnk_id }));
    } else {
      dispatch(
        ordActions.setCartDataObj({
          key: counter_product_lnk_id,
          value: {
            ...currentProd,
            cartQty: newQty,
          },
        })
      );
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (!value) {
      // If search is empty, reset to show all products
      setFilteredProducts([]);
      return;
    }

    // Perform case-insensitive search across multiple fields
    const searchResults = productsData?.filter(
      (item: any) =>
        item?.companyProducts?.name?.toLowerCase().includes(value.toLowerCase()) ||
        item?.counter_ff_div_id?.company_division_code?.division_name?.toLowerCase().includes(value.toLowerCase()) ||
        item?.companyProducts?.ptr?.toString().includes(value)
    );

    setFilteredProducts(searchResults || []);
  };
  const fetchProductsData = async () => {
    try {
      setIsLoading(true);
      const res = await getCounterOrderProductsById(
        window.sessionStorage.getItem("counterCompanyLocationId") || selectedCounterData?.counter_company_location_id,
        "",
        ""
      );
      dispatch(ordActions.setProductsData(res?.data?.data));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleManualQuantityChange = (item: any, value: string) => {
    const counter_product_lnk_id = item?.counter_product_lnk_id;
    const numValue = Number(value);
    const minOrderQty = item?.counter_product_slab?.[0]?.min_order_qty || 1;

    // Validate input
    // if (isNaN(numValue) || numValue < minOrderQty) {
    //   // If input is invalid or less than minimum order quantity, remove from cart
    //   // dispatch(ordActions.setCartDataObj({ key: counter_product_lnk_id }));

    //   // Optionally show a toast if the quantity is less than minimum
    //   if (numValue < minOrderQty) {
    //     toast({
    //       description: `Minimum order quantity for this product is ${minOrderQty}`,
    //       variant: "destructive",
    //     });
    //   }

    //   return;
    // }

    // dispatch(
    //   ordActions.setCartDataObj({
    //     key: counter_product_lnk_id,
    //     value: {
    //       ...item,
    //       cartQty: numValue,
    //     },
    //   })
    // );
    if (isNaN(numValue)) {
      return;
    }

    dispatch(
      ordActions.setCartDataObj({
        key: counter_product_lnk_id,
        value: {
          ...item,
          cartQty: numValue,
        },
      })
    );

    if (numValue < minOrderQty) {
      toast({
        description: `Minimum order quantity for this product is ${minOrderQty}`,
        variant: "destructive",
      });
    }
  };

  const fetchCounterData = async (counter_company_location_id: number) => {
    const query = `?counter_company_location_id=${counter_company_location_id}`;

    try {
      const response = await getCounterByEmployee(query);
      dispatch(ordActions.setSelectedCounterData(response?.data?.data?.[0]));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!productsData || productsData?.length === 0) {
      fetchProductsData();
    }
    if (!selectedCounterData || selectedCounterData.length === 0) {
      fetchCounterData(Number(window.sessionStorage.getItem("counterCompanyLocationId")));
    }
  }, []);

  const displayProducts = searchTerm ? filteredProducts : productsData;

  return (
    <div>
      <div>
        {isLoading ? (
          <div className="flex items-center justify-center h-screen">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="p-6 px-10 border-b shadow-md">
              <Button className="text-lg px-20 text-[#7F56D9]" onClick={() => navigate(`/transactions/orders/details/${counterCode}`)}>
                <img src={PurpleLeftArrowIcon} alt="back" className="mr-4" />
                Back to Pharmacies
              </Button>
              <div className="w-full px-20">
                <h1 className="font-semibold text-[2rem]">{selectedCounterData?.counter_location?.counter_hdr_id?.counter_name}</h1>
                <div className="flex items-center justify-between gap-2 mt-2">
                  <div className="flex items-center gap-2">
                    <p className="font-semibold text-[.9rem] w-fit px-2 py-1 rounded-xl bg-[#EAF4FF] text-[#00527C]">
                      {selectedCounterData?.counter_location?.counter_hdr_id?.counter_code}
                    </p>
                    <p className="text-[#5E4200] w-fit px-2 py-1 rounded-xl font-semibold bg-[#FFF1E3] text-[.9rem]">
                      {selectedCounterData?.drph_lnk_type?.dr_ph_lnk_code}
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <Input placeholder="Search for products" value={searchTerm} onChange={handleSearchChange} className="h-12" />
                    <Button onClick={handleReviewOrder} className="bg-[#8051FF] text-white h-12 w-[12rem]">
                      Review Order
                    </Button>
                  </div>
                </div>{" "}
              </div>
            </div>
            {
              <div className="px-20 mt-6">
                {/* Header Section */}

                {/* Products Table */}
                <div className="bg-white p-6 rounded-lg shadow-sm">
                  <h2 className="font-semibold text-lg mb-4">{searchTerm ? `Search Results (${displayProducts?.length})` : "Products"}</h2>
                  <div className="overflow-x-auto rounded-lg">
                    <table className="min-w-full table-auto border-collapse border border-gray-200">
                      <thead className="bg-gray-100">
                        <tr>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Product Name</th>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Division</th>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Scheme</th>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Price (PTR)</th>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Min Order Qty</th>
                          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 border-b border-gray-200">Max Order Qty</th>

                          <th className="px-6 py-3 w-[14rem] text-left text-sm font-medium text-gray-600 border-b border-gray-200">Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayProducts?.length === 0 ? (
                          <tr>
                            <td colSpan={6} className="text-center text-gray-500 py-6">
                              No products found matching your search.
                            </td>
                          </tr>
                        ) : (
                          displayProducts?.map((item: any) => (
                            <tr key={item?.counter_product_lnk_id} className="hover:bg-gray-50 border-b border-gray-200">
                              <td className="px-6 py-4 text-sm text-gray-800">{item?.companyProducts?.name}</td>
                              <td className="px-6 py-4 text-sm text-gray-800">
                                {item?.counter_ff_div_id?.company_division_code?.division_name || "-"}
                              </td>
                              <td className="w-[14rem] px-6 py-6 flex justify-between text-sm text-gray-800">
                                {item?.priceldentifier?.description || "-"} :
                                {item?.priceldentifier?.priceldentifier_id == "2" && (
                                  <div className="rounded-md">
                                    <span className="text-sm border border-gray-300 px-2 py-1 rounded-md text-gray-900">
                                      {item?.counter_product_slab?.[0]?.schemeqty_cp} + {item?.counter_product_slab?.[0]?.quantity_cp}
                                    </span>
                                  </div>
                                )}
                                {/* Fixed Price Section */}
                                {item?.priceldentifier?.priceldentifier_id == "3" && item?.counter_product_slab?.[0]?.fixedprice_cp && (
                                  <div className="rounded-md">
                                    <span className="text-sm border border-gray-300 px-2 py-1 rounded-md text-gray-900">
                                      ₹{item?.counter_product_slab?.[0]?.fixedprice_cp}
                                    </span>
                                  </div>
                                )}
                                {/* Discount Section */}
                                {item?.priceldentifier?.priceldentifier_id == "1" && item?.counter_product_slab?.[0]?.discountonptr_cp && (
                                  <div className="rounded-md">
                                    <span className="text-sm border border-gray-300 px-2 py-1 rounded-md text-gray-900">
                                      {item?.counter_product_slab?.[0]?.discountonptr_cp}%
                                    </span>
                                  </div>
                                )}
                              </td>
                              {/* <td className="px-6 py-4 text-sm text-gray-800">₹{item?.companyProducts?.ptr || "N/A"}</td> */}
                              <td className="px-6 py-4 text-sm text-gray-800">₹{item?.counter_product_slab?.[0]?.price}</td>
                              <td className="px-6 py-4 text-sm text-gray-800">{item?.counter_product_slab?.[0]?.min_order_qty || "N/A"}</td>
                              <td className="px-6 py-4 text-sm text-gray-800">{item?.counter_product_slab?.[0]?.max_order_qty || "N/A"}</td>
                              <td className="px-6 py-4 text-sm text-gray-800">
                                {!cartDataObj?.[item?.counter_product_lnk_id] ? (
                                  <button
                                    onClick={() => handleAddToCart(item)}
                                    className="bg-[#8051FF] text-white px-4 py-2 rounded-lg hover:bg-[#6A42E4]"
                                  >
                                    <div className="flex items-center">
                                      <img src={cross} alt="cross" className="w-4 h-4 mr-2 rotate-45" />
                                      Add
                                    </div>
                                  </button>
                                ) : (
                                  <div className="flex items-center gap-2 w-fit">
                                    <button
                                      onClick={() => handleDecrement(item?.counter_product_lnk_id)}
                                      className="px-3 py-1 rounded-lg border border-gray-300 bg-white hover:bg-gray-100"
                                    >
                                      -
                                    </button>
                                    <Input
                                      enableArrows={false}
                                      showIcon={false}
                                      type="number"
                                      value={cartDataObj?.[item?.counter_product_lnk_id]?.cartQty}
                                      onChange={(e) => handleManualQuantityChange(item, e.target.value)}
                                      className="py-1 w-16 text-center border rounded-lg border-gray-300"
                                    />
                                    <button
                                      onClick={() => handleIncrement(item?.counter_product_lnk_id)}
                                      className="px-3 py-1 rounded-lg border border-gray-300 bg-white hover:bg-gray-100"
                                    >
                                      +
                                    </button>
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            }
          </>
        )}
      </div>
    </div>
  );
};

export default OrdersProductScreen;

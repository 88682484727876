import { yupResolver } from "@hookform/resolvers/yup";
import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import downArrow from "src/assets/down-arrow.svg";
import { Button, Input } from "src/components";
import Breadcrumb from "src/components/ui/breadcrumb.component";
import Loader from "src/components/ui/loader.component";
import { flattenObject } from "src/constants/functions";
import SmartForm from "src/container/smart-form.component";
import { invoiceActions } from "src/context/invoice-slice";
import { RootState } from "src/context/store";
import { toast } from "src/hooks/useToast";
import {
  editInvoice,
  getInvoiceDetails,
  getInvoicePriceList,
  getOrderedProducts,
  getSimilarCompanyProducts,
  updateInvoice,
} from "src/services/invoice-api";
import * as yup from "yup";
import SubTable from "../counter-details/sub-table.component";
import { getInvoiceDetailsInfo } from "../invoice-master/invoice-helper";
import InvoiceLayout from "../invoice-master/invoice-layout.component";
import InvoiceDetailCard from "./invoice-detail.component";
import { InvoiceApprovalScreen } from "./InvoiceApprovalScreen";

const tabs = ["Entry", "Approval"];

const schema = yup.object({
  // itemCode: yup.object().required("Item Code is required"),
  // itemName: yup.object().required("Item Name is required"),
  mrp: yup.number().required("MRP is required"),
  price: yup.number().required("Price is required"),
  schemeType: yup.object().required("Scheme Type is required"),
  batchNo: yup.string().required("Batch No. is required"),
});

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("item_description", {
    header: "Item Name",
    cell: (info: any) => info.row.original.item_description || "-",
  }),
  columnHelper.accessor("item_code", {
    header: "Item Code",
    cell: (info: any) => info.row.original.item_code || "-",
  }),
  columnHelper.accessor("batch_no", {
    header: "Batch No.",
    cell: (info: any) => info.row.original.batch_no || "-",
  }),
  columnHelper.accessor("ptr", {
    header: "Inv. PTR",
    cell: (info: any) => info.row.original.ptr || "-",
  }),
  columnHelper.accessor("inv_mrp", {
    header: "Inv. MRP",
    cell: (info: any) => info.row.original.inv_mrp || "-",
  }),
  columnHelper.accessor("quantity", {
    header: "Qty.",
    cell: (info: any) => info.row.original.quantity,
  }),
  columnHelper.accessor("price_identifier", {
    header: "Discount Defined By",
    cell: (info: any) => info.row.original.price_identifier || "-",
  }),
  columnHelper.accessor("discount_amt", {
    header: "Discount %",
    cell: (info: any) => ((info.row.original.discount_amt / info.row.original.gross_amt) * 100)?.toFixed(2) || "",
  }),
  columnHelper.accessor("gross_amt", {
    header: "Gross Amt.",
    cell: (info: any) => info.row.original.gross_amt || "-",
  }),
  columnHelper.accessor("gst", {
    header: "GST %",
    cell: (info: any) => info.row.original.gst || "-",
  }),
];

interface selectedPriceIdentifier {
  key: string;
  description: string;
  priceldentifier_id: number;
}

const priceIdentifierList = [
  {
    priceldentifier_id: 1,
    description: "Discount Percent",
    key: "DISCOUNT_PERCENT",
  },
  {
    priceldentifier_id: 2,
    description: "Discount Amount",
    key: "DISCOUNT_AMOUNT",
  },
  {
    priceldentifier_id: 3,
    description: "Scheme",
    key: "SCHEME",
  },
];
export const InvoiceEntryScreen = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [addedProducts, setAddedProducts] = useState<any[]>([]);
  const [isLoading2, setIsLoading2] = useState(false);
  const [castDiscountPercent, setCastDiscountPercent] = useState<number>(0);
  const [castDiscountAmount, setCastDiscountAmount] = useState<number>(0);
  const { invoiceDetails } = useSelector((state: RootState) => state.invoice);
  // const [priceIdentifierList, setPriceIdentifierList] = useState([]);
  const [selectedPriceIdentifier, setSelectedPriceIdentifier] = useState<selectedPriceIdentifier>({
    key: "",
    description: "",
    priceldentifier_id: 0,
  });
  const [quantity, setQuantity] = useState<number>(0);
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [useOrderedProducts, setUseOrderedProducts] = useState(false);
  const [doManualEntry, setDoManualEntry] = useState(false);
  const [unknownEntry, setUnknownEntry] = useState<any[]>([]);
  const [itemCode, setItemCode] = useState<string>("");
  const [priceList, setPriceList] = useState<any[]>([]);
  const [gstAmbiguous, setGstAmbiguous] = useState<boolean>(false);

  const smartFormInstance = useForm({
    // @ts-ignore
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: structuredClone({}),
  });

  const dispatch = useDispatch();
  const { stockist_invoice_attachment_id } = useParams();

  // const subtotal = invoiceDetails?.invoice?.invoiceProducts?.reduce((acc: number, curr: any) => acc + Number(curr?.quantity) * Number(curr?.ptr), 0);
  const subtotal = invoiceDetails?.invoice?.invoiceProducts?.reduce(
    (acc: number, curr: any) => acc + Number(curr?.gross_amt) + Number(curr?.gross_amt) * (Number(curr?.gst) / 100),
    0
  );

  const handleCashDiscountPercentChange = (e: number) => {
    if (e > 0) {
      setCastDiscountAmount((e * subtotal) / 100);
      // setCastDiscountPercent(e);
    } else {
      setCastDiscountAmount(0);
      // setCastDiscountPercent(0);
    }
  };

  const handleCashDiscountAmountChange = (e: number) => {
    if (e > 0) {
      setCastDiscountPercent((e / subtotal) * 100);
      // setCastDiscountAmount(e);
    } else {
      setCastDiscountPercent(0);
      // setCastDiscountAmount(0);
    }
  };

  let subrow: any[] = [];
  let subrow2: any[] = [];

  addedProducts?.forEach((element: any) => {
    subrow.push(flattenObject(element));
  });
  unknownEntry?.forEach((element: any) => {
    subrow2.push(flattenObject(element));
  });

  const fetchInvoiceDetails = async () => {
    setIsLoading(true);
    try {
      const response = await getInvoiceDetails(stockist_invoice_attachment_id as string, false);
      setAddedProducts(response?.data?.data?.invoice?.invoiceProducts.filter((item: any) => item?.is_item_unknown === false));
      dispatch(invoiceActions.setInvoiceDetails(response?.data?.data));
      setUnknownEntry(response?.data?.data?.invoice?.invoiceProducts.filter((item: any) => item?.is_item_unknown === true));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleFormSubmit = async (data: any) => {
    setIsLoading2(true);
    const payload = {
      data: {
        invoice_id: Number(invoiceDetails?.invoice?.invoice_id),
        item_description: String(data?.itemName),
        item_code: String(data?.itemCode),
        quantity: Number(quantity),
        gst: data?.gst,
        ptr: Number(price),
        price_identifier_id: String(selectedPriceIdentifier?.description),
        batch_no: data?.batchNo as string,
        inv_rate: String(price),
        inv_mrp: String(data?.mrp),
        discount_amt: String(discountAmount),
        counter_code: invoiceDetails?.counter_code,
        is_item_unknown: doManualEntry,
      },
      primary_key: invoiceDetails?.invoice?.invoice_id,
      event_type: "INVOICE_PRODUCT_ADD",
    };

    // if (doManualEntry) {
    //   const modifiedItem = {
    //     ...payload,
    //     price_identifier: selectedPriceIdentifier?.description,
    //   };
    //   setUnknownEntry([...unknownEntry, modifiedItem]);
    //   setIsLoading2(false);
    //   console.log(payload, "payload");
    //   return;
    // }

    try {
      // console.log(payload, "payload");
      const res = await updateInvoice(payload);
      if (res) {
        setTimeout(async () => {
          const response = await getInvoiceDetails(stockist_invoice_attachment_id as string, true);
          // setAddedProducts(response?.data?.data?.invoice?.invoiceProducts);
          console.log(response);
          setIsLoading2(false);
          fetchInvoiceDetails();
        }, 2000);
        toast({ description: "Product added successfully", variant: "default" });
        console.log(res);
      }
    } catch (error) {
      console.log(error, "error");
      toast({ description: "Product add failed", variant: "destructive" });
      setIsLoading2(false);
    }
  };

  const handleAddItem = () => {
    setOpenForm(true);
  };

  const handleConfirmInvoice = async () => {
    setIsLoading2(true);
    const payload = {
      data: {
        invoice_id: Number(invoiceDetails?.invoice?.invoice_id),
        invoice_number: invoiceDetails?.invoice?.invoice_number,
        cash_discount_perc: castDiscountPercent,
        cash_discount: castDiscountAmount,
        net_amount: subtotal - castDiscountAmount,
        stockist_invoice_attachment_id: invoiceDetails?.stockist_invoice_attachment_id,
        gst_perc: 18,
        gross_amount: subtotal,
        u_code: "USER149",
        confirm: true,
      },
      primary_key: Number(invoiceDetails?.invoice?.invoice_id),
      event_type: "INVOICE_EDIT",
    };
    try {
      const res = await editInvoice(payload);

      if (res) {
        setTimeout(async () => {
          setIsLoading2(false);
          fetchInvoiceDetails();
        }, 2000);
        toast({ description: "Invoice confirmed successfully", variant: "default" });
      }
    } catch (error) {
      console.log(error);
      toast({ description: "Invoice confirmation failed", variant: "destructive" });
      setIsLoading2(false);
    }
  };

  const fetchProductsData = async (query: string) => {
    if (doManualEntry) {
      return;
    }
    try {
      return await getSimilarCompanyProducts(invoiceDetails?.company_code, invoiceDetails?.division_code, query, "", 1, 10);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProductsDataForItemCode = async (query: string) => {
    if (doManualEntry) {
      return;
    }
    try {
      return await getSimilarCompanyProducts(invoiceDetails?.company_code, invoiceDetails?.division_code, "", query, 1, 10);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOrderedProducts = async (query: string) => {
    if (doManualEntry) {
      return;
    }
    try {
      return await getOrderedProducts({
        product_name: query,
        counter_code: invoiceDetails?.counter_code,
        item_code: "",
        page: 1,
        page_size: 10,
        company_stockist_code: invoiceDetails?.company_stockist_code,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOrderedProductsByItemCode = async (query: string) => {
    if (doManualEntry) {
      return;
    }
    try {
      return await getOrderedProducts({
        product_name: "",
        counter_code: invoiceDetails?.counter_code,
        item_code: query,
        page: 1,
        page_size: 10,
        company_stockist_code: invoiceDetails?.company_stockist_code,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePercentageChange = (e: any) => {
    const { setValue }: any = smartFormInstance;
    const percentage = Number(e) / 100;
    const discountAmount = percentage * quantity * price;
    setDiscountAmount(Number(discountAmount?.toFixed(2)));
    setDiscountPercentage(Number(e));
    setValue("discountAmount", discountAmount?.toFixed(2));
    setValue("discountPercentage", Number(e));
  };

  const handleAmountChange = (e: any) => {
    const { setValue }: any = smartFormInstance;
    const amount = Number(e);
    const discountPercentage = (amount / (quantity * price)) * 100;
    setDiscountAmount(amount);
    setDiscountPercentage(Number(discountPercentage?.toFixed(2)));
    setValue("discountAmount", amount);
    setValue("discountPercentage", discountPercentage?.toFixed(2));
  };

  const handleAutocompleteChange = async (data: any) => {
    const { setValue }: any = smartFormInstance;
    const value = data;
    if (value) {
      setValue("itemName", value?.product_name);
      setValue("itemCode", value?.item_code);
      // setValue("mrp", value?.mrp || "");
      // setValue("price", value?.ptr || "");
      setPrice(value?.ptr);
      setValue("batchNo", value?.batchNo || "");
      setValue("discountAmount", discountAmount || "");
      setValue("discountPercentage", discountPercentage || "");
      setItemCode(value?.item_code);
    } else {
      // setValue("mrp", "");
      // setValue("price", "");
      setValue("schemeType", "");
      setValue("freeQty", "");
      setValue("discountPercentage", "");
      setValue("discountAmount", "");
      setValue("batchNo", "");
      setValue("itemCode", "");
    }
  };

  const resetFormFields = () => {
    const { reset }: any = smartFormInstance;

    // Reset form fields
    reset();

    // Clear additional state values
    setQuantity(0);
    setPrice(0);
    setDiscountPercentage(0);
    setDiscountAmount(0);
    setSelectedPriceIdentifier({
      key: "",
      description: "",
      priceldentifier_id: 0,
    });
  };

  useEffect(() => {
    const fetchInvoicePriceList = async () => {
      if (itemCode) {
        const res = await getInvoicePriceList(itemCode);
        setPriceList(res?.data?.data);
      }
    };

    fetchInvoicePriceList();
  }, [itemCode]);

  useEffect(() => {
    fetchInvoiceDetails();
  }, []);

  useEffect(() => {
    // if (invoiceDetails?.division_code) fetchPriceIdentifierList(invoiceDetails?.division_code);
  }, [invoiceDetails?.division_code]);

  if (isLoading || isLoading2) {
    return <Loader />;
  }

  return (
    <InvoiceLayout>
      <div className="flex justify-between px-[3.75rem] mt-6  pb-5">
        <div className="">
          <Breadcrumb
            currentActive="invoice"
            dataOrigin={[
              { name: "Transactions", path: "transactions" },
              { name: "Invoices", path: "invoice" },
            ]}
          />
          <h1 className="text-[#49484C] font-bold text-[2rem]">Invoices</h1>
          {/* <p className="font-medium text-base text-[#929194]">Invoices</p> */}
        </div>

        <div className="flex gap-2 items-center">
          <Input placeholder="Search" className="px-3 h-[32px] w-[180px] rounded-lg " showIcon={true} />
        </div>
      </div>
      <Tab.Group>
        <Tab.List className="relative px-[3.75rem] mb-[1.16rem] mt-0 border-b-[1px] border-b-[#E9E9E9] shadow-md">
          {tabs.map((item: string) => (
            <Tab key={item} as={Fragment}>
              {({ selected }: { selected: boolean }) => (
                <div
                  className={`relative inline-block cursor-pointer -bottom-[1px] px-4 py-3 font-semibold text-sm  border-b-2  not-italic  hover:border-b-[#586AF5] hover:text-[#586af5] focus:outline-none ${
                    selected ? "text-[#586af5] border-b-[#586AF5]" : "text-[rgba(0,0,0,0.4)] border-b-transparent"
                  }`}
                >
                  {item}
                </div>
              )}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels>
          <Tab.Panel className="px-[3.75rem]">
            <div className="px-[3.75rem] mx-auto mt-10">
              <ul className="mt-5">
                <li className="my-5 mb-10 rounded-lg ">
                  <InvoiceDetailCard
                    isExpandedInitial={true}
                    isExpandable={false}
                    status={invoiceDetails?.fileStatusHdr?.fuid_status_desc}
                    checkDataType={false}
                    data={getInvoiceDetailsInfo(invoiceDetails)}
                    viewDetails={false}
                    invoiceData={invoiceDetails}
                    handleAddItem={handleAddItem}
                    fetchInvoiceDetails={fetchInvoiceDetails}
                  />
                </li>
              </ul>
              {openForm && (
                <div className="p-0 mb-10 border-2 rounded-lg">
                  <div className="m-5">
                    <div className="flex items-center justify-between">
                      <p className="mb-2 text-lg font-semibold">Invoice Details</p>
                      <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black" onClick={handleToggle}>
                        <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
                      </Button>
                    </div>
                    <div className="flex items-center gap-x-5">
                      {/* <p className="mb-5 text-sm text-gray-500">Item #1</p> */}
                      <div className="flex items-center mb-5">
                        <input
                          type="checkbox"
                          id="useOrderedProducts"
                          checked={useOrderedProducts}
                          onChange={() => setUseOrderedProducts(!useOrderedProducts)}
                          className="mr-2"
                        />
                        <label htmlFor="useOrderedProducts" className="text-sm text-gray-500">
                          Use Ordered Products
                        </label>
                      </div>
                      <div className="flex items-center mb-5">
                        <input
                          type="checkbox"
                          id="doManualEntry"
                          checked={doManualEntry}
                          onChange={() => setDoManualEntry(!doManualEntry)}
                          className="mr-2"
                        />
                        <label htmlFor="doManualEntry" className="text-sm text-gray-500">
                          Do Manual Entry
                        </label>
                      </div>
                    </div>
                    <div
                      className={`overflow-hidden transition-all duration-500 ease-in-out ${
                        isExpanded ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
                      }`}
                      style={{ transitionProperty: "max-height, opacity" }}
                    >
                      <SmartForm
                        onFormSubmit={handleFormSubmit}
                        schema={schema}
                        isControlled={false}
                        formInstance={smartFormInstance}
                        defaultValues={{}}
                      >
                        <div className="grid grid-cols-4 gap-4">
                          <SmartForm.Autocomplete
                            className="w-full z-10"
                            suggestionList={[]}
                            placeholder=" "
                            fieldName="itemCode"
                            label="Item Code"
                            showRequiredMark={false}
                            uniqueKey="id"
                            displayKey="item_code"
                            allowCustomValue={true}
                            onChange={(value) => {
                              // update other fields from this if it exists
                              handleAutocompleteChange(value);
                            }}
                            // additionDisplayKey="companyProducts.item_code"
                            isControlled={false}
                            asyncListFunction={useOrderedProducts ? fetchOrderedProductsByItemCode : fetchProductsDataForItemCode}
                          />
                          <SmartForm.Autocomplete
                            className="w-full z-10"
                            suggestionList={[]}
                            placeholder=" "
                            fieldName="itemName"
                            label="Item Name"
                            showRequiredMark={false}
                            uniqueKey="id"
                            displayKey={"product_name"}
                            allowCustomValue={true}
                            onChange={(value) => {
                              // update other fields from this if it exists
                              handleAutocompleteChange(value);
                            }}
                            isControlled={false}
                            asyncListFunction={useOrderedProducts ? fetchOrderedProducts : fetchProductsData}
                          />
                          <SmartForm.Autocomplete
                            className="w-full z-10"
                            suggestionList={priceList}
                            placeholder=" "
                            fieldName="itemPrice"
                            label="MRP"
                            showRequiredMark={false}
                            uniqueKey="company_productprice_id"
                            displayKey={"mrp"}
                            allowCustomValue={true}
                            onChange={(value) => {
                              // update other fields from this if it exists
                              // handleAutocompleteChange(value);
                              const { setValue }: any = smartFormInstance;
                              setValue("mrp", value?.mrp);
                              setValue("price", value?.ptr);
                              setValue("ptr", value?.ptr);
                              setValue("gst", value?.company_productcode?.gst_pc);
                              setGstAmbiguous(value?.company_productcode?.gst_ambiguous);
                            }}
                            isControlled={false}
                            // asyncListFunction={}
                          />
                          <SmartForm.Input
                            className="w-full"
                            inputClassName="w-full"
                            type="text"
                            placeholder=" "
                            fieldName="ptr"
                            label="PTR"
                            disabled={true}
                            showRequiredMark={false}
                          />
                          <SmartForm.Input
                            className="w-full"
                            inputClassName="w-full"
                            type="number"
                            placeholder=" "
                            fieldName="price"
                            label="Price"
                            showRequiredMark={false}
                            changeHandler={(e: any) => {
                              setPrice(e);
                            }}
                          />
                          <SmartForm.Input
                            className="w-full"
                            inputClassName="w-full"
                            type="number"
                            placeholder=" "
                            fieldName="gst"
                            label="GST%"
                            showRequiredMark={false}
                            disabled={!gstAmbiguous}
                          />
                          <SmartForm.Input
                            className="w-full"
                            inputClassName="w-full"
                            type="number"
                            placeholder=" "
                            fieldName="quantity"
                            label="Quantity"
                            value={quantity}
                            showRequiredMark={false}
                            changeHandler={(e: any) => setQuantity(e)}
                          />
                          <SmartForm.Autocomplete
                            className="w-full"
                            suggestionList={priceIdentifierList}
                            placeholder=" "
                            fieldName="schemeType"
                            label="Scheme Type"
                            showRequiredMark={false}
                            uniqueKey="key"
                            displayKey="description"
                            onChange={(val) => {
                              setSelectedPriceIdentifier(val);
                            }}
                          />
                          {selectedPriceIdentifier?.key == "SCHEME" && (
                            <SmartForm.Input
                              className="w-full"
                              inputClassName="w-full"
                              type="text"
                              label="Free Qty"
                              showRequiredMark={false}
                              placeholder=" "
                              fieldName="freeQty"
                            />
                          )}

                          {(selectedPriceIdentifier?.key == "DISCOUNT_PERCENT" || selectedPriceIdentifier?.key == "DISCOUNT_AMOUNT") && (
                            <>
                              {" "}
                              <SmartForm.Input
                                className="w-full"
                                inputClassName="w-full"
                                type="text"
                                label="Discount Percent"
                                showRequiredMark={false}
                                placeholder=" "
                                value={discountPercentage}
                                fieldName="discountPercentage"
                                changeHandler={handlePercentageChange}
                              />
                              <SmartForm.Input
                                className="w-full"
                                inputClassName="w-full"
                                type="number"
                                label="Discount Amount"
                                showRequiredMark={false}
                                placeholder=" "
                                fieldName="discountAmount"
                                value={discountAmount}
                                changeHandler={handleAmountChange}
                              />{" "}
                            </>
                          )}

                          <SmartForm.Input
                            className="w-full"
                            inputClassName="w-full"
                            type="text"
                            label="Batch No."
                            showRequiredMark={false}
                            placeholder=" "
                            fieldName="batchNo"
                          />
                        </div>
                        <div className="flex justify-end mt-5 gap-x-2 pb-5">
                          <Button className="font-normal text-gray-700 bg-[#F1F1F1] h-9 border-2 border-gray-200" onClick={resetFormFields}>
                            Add another item
                          </Button>

                          <Button className="h-9 text-white font-normal bg-[#8051FF] shadow-sm" type="submit">
                            Add Product
                          </Button>
                        </div>
                      </SmartForm>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex flex-col mt-5 gap-x-5 xl:flex-row">
                <div className="w-full p-8 border rounded-lg xl:w-9/12 flex flex-col gap-4">
                  <SubTable title="Items Added" subtitle="Review and manage all items added" showheader={true} columns={columns} data={subrow} />
                  <SubTable title="Unknown Order" subtitle="Review and manage all items added" showheader={true} columns={columns} data={subrow2} />
                </div>
                <div className="w-full p-8 mt-10 border rounded-lg xl:w-3/12 xl:mt-0">
                  <p className="mb-2 text-lg font-semibold">Calculations</p>
                  <div className="flex flex-col pb-10 gap-y-1">
                    <p className="flex justify-between py-2 text-sm">
                      Subtotal <span className="">₹{subtotal?.toFixed(2)}</span>
                    </p>
                    <p className="flex py-2 text-sm justify-between">
                      <span className="mr-2">Cash Discount %</span>
                      <div className="">
                        <Input
                          enableArrows={false}
                          showIcon={false}
                          type="number"
                          placeholder="0"
                          value={castDiscountPercent?.toFixed(2)}
                          onChange={(e: any) => handleCashDiscountPercentChange(e?.target?.value)}
                          className="py-1 text-right w-24 border rounded-lg border-gray-300"
                        />{" "}
                      </div>
                    </p>
                    <p className="flex justify-between py-2 text-sm">
                      <span className="mr-2">Cash Discount Amount</span>
                      <div className="">
                        <Input
                          enableArrows={false}
                          showIcon={false}
                          type="number"
                          placeholder="0"
                          value={castDiscountAmount?.toFixed(2)}
                          onChange={(e: any) => handleCashDiscountAmountChange(e?.target?.value)}
                          className="py-1 text-right w-24 border rounded-lg border-gray-300"
                        />{" "}
                      </div>
                    </p>
                    <p className="flex justify-between py-2 text-sm">
                      Total (as calculated) <span className="font-semibold">₹{(subtotal - castDiscountAmount)?.toFixed(2)}</span>
                    </p>
                    <p className="flex justify-between py-2 text-sm">
                      <span className="mr-2">Net Inv. Amt.</span>
                      <div className="">
                        <Input
                          enableArrows={false}
                          showIcon={false}
                          type="number"
                          placeholder=""
                          value={(subtotal - castDiscountAmount)?.toFixed(2)}
                          className="py-1 text-right w-24 border rounded-lg border-gray-300"
                        />{" "}
                      </div>
                    </p>
                    <Button
                      className="w-full h-9 mt-5 font-normal text-white bg-[#8051FF] shadow-sm"
                      isLoading={isLoading2}
                      onClick={handleConfirmInvoice}
                    >
                      Confirm Invoice
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel className="px-[3.75rem]">
            <InvoiceApprovalScreen />
          </Tab.Panel>
          <Tab.Panel className="px-[3.75rem]">{/* <InvoiceEntryScreen /> */}</Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </InvoiceLayout>
  );
};

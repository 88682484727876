import { isProd } from "./global-const";

export const getHeaderLinks = (accessControl: any): any[] => {
  if (isProd) {
    return [
      { label: "Masters", value: "masters", roles: accessControl.menus?.MASTERS?.ALLOWED_ROLES || ["Any"] },
      { label: "Request Approval", value: "request-approval", roles: accessControl.menus?.REQUEST_APPROVAL?.ALLOWED_ROLES || ["Any"] },
      { label: "Transactions", value: "transactions", roles: accessControl.menus?.MASTERS?.ALLOWED_ROLES || ["Any"] },
    ];
  } else {
    return [
      { label: "Masters", value: "masters", roles: accessControl.menus?.MASTERS?.ALLOWED_ROLES || ["Any"] },
      { label: "Request Approval", value: "request-approval", roles: accessControl.menus?.REQUEST_APPROVAL?.ALLOWED_ROLES || ["Any"] },
      { label: "Transactions", value: "transactions", roles: accessControl.menus?.MASTERS?.ALLOWED_ROLES || ["Any"] },
    ];
  }
};

export const getSubHeaderLinks = (accessControl: any) => {
  if (isProd) {
    return [
      { label: "Employees", value: "masters/Employees", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.EMPLOYEES?.ALLOWED_ROLES || ["Any"] },
      { label: "Products", value: "masters/Products", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.PRODUCTS.ALLOWED_ROLES || ["Any"] },
      { label: "Counters", value: "masters/Counters", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.COUNTERS?.ALLOWED_ROLES || ["Any"] },
      { label: "Stockists", value: "masters/Stockists", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.STOCKISTS?.ALLOWED_ROLES || ["Any"] },
      // { label: "Counters", value: "Counters", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.COUNTERS?.ALLOWED_ROLES || ["Any"] },
      { label: "Divisions", value: "masters/divisions", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.DIVISIONS?.ALLOWED_ROLES || ["Any"] },
      { label: "Geographic", value: "masters/geographic", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.GEOGRAPHIC?.ALLOWED_ROLES || ["Any"] },
      { label: "Invoice", value: "masters/invoice", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.INVOICE?.ALLOWED_ROLES || ["Any"] },
    ];
  } else {
    return [
      { label: "Employees", value: "masters/Employees", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.EMPLOYEES?.ALLOWED_ROLES || ["Any"] },
      { label: "Products", value: "masters/Products", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.PRODUCTS.ALLOWED_ROLES || ["Any"] },
      { label: "Counters", value: "masters/Counters", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.COUNTERS?.ALLOWED_ROLES || ["Any"] },
      { label: "Divisions", value: "masters/Divisions", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.DIVISIONS?.ALLOWED_ROLES || ["Any"] },
      { label: "Stockists", value: "masters/Stockists", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.STOCKISTS?.ALLOWED_ROLES || ["Any"] },
      { label: "Geographic", value: "masters/geographic", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.GEOGRAPHIC?.ALLOWED_ROLES || ["Any"] },
      { label: "Company", value: "masters/Company", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.GEOGRAPHIC?.ALLOWED_ROLES || ["Any"] },
      { label: "Invoice", value: "masters/invoice", roles: accessControl.menus?.MASTERS?.SECONDARY_MENUS.INVOICE?.ALLOWED_ROLES || ["Any"] },
    ];
  }
};

export const getSubHeaderLinks2 = (accessControl: any) => {
  if (isProd) {
    return [
      { label: "PRA", value: "request-approval/Pra", roles: accessControl.menus?.REQUEST_APPROVAL?.SECONDARY_MENUS.PRA?.ALLOWED_ROLES || ["Any"] },
      { label: "CRA", value: "request-approval/Cra", roles: accessControl.menus?.REQUEST_APPROVAL?.SECONDARY_MENUS.CRA?.ALLOWED_ROLES || ["Any"] },
    ];
  } else {
    return [
      { label: "PRA", value: "request-approval/Pra", roles: accessControl.menus?.REQUEST_APPROVAL?.SECONDARY_MENUS.PRA?.ALLOWED_ROLES || ["Any"] },
      { label: "CRA", value: "request-approval/Cra", roles: accessControl.menus?.REQUEST_APPROVAL?.SECONDARY_MENUS.CRA?.ALLOWED_ROLES || ["Any"] },
    ];
  }
};

export const getTransactionsSubHeaderLinks = (accessControl: any) => {
  if (isProd) {
    return [
      { label: "Orders", value: "transactions/orders", roles: accessControl.menus?.MASTERS?.ALLOWED_ROLES || ["Any"] },
      { label: "Invoices", value: "transactions/invoice", roles: accessControl.menus?.MASTERS?.ALLOWED_ROLES || ["Any"] },
    ];
  } else {
    return [
      { label: "Orders", value: "transactions/orders", roles: accessControl.menus?.MASTERS?.ALLOWED_ROLES || ["Any"] },
      { label: "Invoices", value: "transactions/invoice", roles: accessControl.menus?.MASTERS?.ALLOWED_ROLES || ["Any"] },
    ];
  }
};
